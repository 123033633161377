<template>
  <div class="container p-3">
    <div class="f-xl text-center mb-3 f-bold">รายชื่อเพื่อน</div>
    <b-card class="border-none shadow-sm rounded-xl">
      <div
        class="color-primary d-inline-flex align-items-center justify-content-between w-100"
      >
        <div class="f-bold">เพื่อนที่ชวนแล้ว</div>
        <span class="badge-count"
          >{{ data.count }}
          <span v-if="data.all > 0">/{{ data.all }}</span></span
        >
      </div>
      <div
        v-for="list of data.data"
        :key="'list-' + list.id"
        class="content-between list-container"
      >
        <div>
          {{ list.name }} <br />
          <small class="text-gray">{{ list.tier }}</small>
        </div>
        <div class="text-right">
          <div>สมัครเมื่อ</div>
          <div>{{ dateFormat(list.register_date) }}</div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      data: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/FriendGetFriend/get_user_invite/${this.$route.params.id}`
      );
      this.data = res.detail;
    },
    dateFormat(date) {
      return moment(date || "").format("DD/MM/YYYY");
      // return moment(date).locale("th").format('lll');
    }
  }
};
</script>

<style>
.badge-count {
  padding: 0.25rem;
  font-size: 75%;
  margin-top: 0.25rem;
  width: 50px;
  text-align: center;
  height: auto;
  /* aspect-ratio: 1; */
  margin-left: 0.5rem;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  border-radius: 0.75rem;
}
.list-container {
  border-bottom: 1px solid whitesmoke;
  padding: 0.5rem 1rem;
}
</style>
